













































































import { Getter, Action, Mutation } from 'vuex-class';
import { required, minLength, helpers } from 'vuelidate/lib/validators';
import { Component, Prop, Vue } from 'vue-property-decorator';
import SignupLoginLayout from '@/components/shared/layouts/SignupLoginLayout.vue';
import { EMAIL_REGEX, USERNAME_REGEX } from '@/scripts/shared';
import { Validations } from 'vuelidate-property-decorators';
import * as types from '@/store/types';
import { TranslateResult } from 'vue-i18n';

const namespace: string = 'user';
const namespaceUser: string = 'error';
const namespacePlatform: string = 'platformConfig';

@Component({
  components: {
    SignupLoginLayout,
  },
})
export default class Login extends Vue {
  @Action(types.LOGIN, { namespace }) login: any;

  @Action(types.SET_ERROR, { namespace: namespaceUser }) setError: any;

  @Getter(types.GET_LOGIN_STATUS, { namespace }) loginStatus: any;

  @Getter(types.GET_PLATFORM_CONFIG, { namespace: namespacePlatform })
  getPlatformConfig: any;

  private usernameEmail: string = '';

  private password: string = '';

  private show = false;

  @Validations()
  validations = {
    password: {
      required,
      minLength: minLength(8),
    },
    usernameEmail: {
      required,
      matches(value: string) {
        return EMAIL_REGEX.test(value) || USERNAME_REGEX.test(value);
      },
    },
  };

  get usernameEmailErrors() {
    const errors: TranslateResult[] = [];

    if (!this.$v.usernameEmail.$dirty) return errors;

    if (!this.$v.usernameEmail.matches) errors.push(this.$t('email_invalid'));
    if (!this.$v.usernameEmail.required) errors.push(this.$t('required_field'));

    return errors;
  }

  get passwordErrors() {
    const errors: TranslateResult[] = [];

    if (!this.$v.password.$dirty) return errors;

    if (!this.$v.password.required) errors.push(this.$t('required_field'));
    if (!this.$v.password.minLength) errors.push(this.$t('password_length'));

    return errors;
  }

  get isLoginDisabled(): boolean {
    return this.$v.$invalid;
  }

  async handleSubmit() {
    this.$v.$touch();

    if (this.isLoginDisabled) return;

    let email: string;
    let userName: string;

    if (this.isEmail()) {
      email = this.usernameEmail;
      userName = '';
    } else {
      email = '';
      userName = this.usernameEmail;
    }

    this.login({
      username: userName,
      email,
      password: this.password,
    }).then(() => {
      if (this.loginStatus) {
        this.$router.push('/');
      }
    });
  }

  isEmail() {
    const value = this.usernameEmail;
    return EMAIL_REGEX.test(value);
  }
}
