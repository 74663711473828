




































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SignupLoginLayout extends Vue {
  get logoToUse() {
    return this.$vuetify.theme.dark ? 'OpenFBA-Logo-Dark.svg' : 'OpenFBA-Logo.svg';
  }
}
